import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TableService {
  private dataSubject = new BehaviorSubject<any[]>([]);
  private sortColumnSubject = new BehaviorSubject<string>('');
  private sortDirectionSubject = new BehaviorSubject<string>('');
  private searchTextSubject = new BehaviorSubject<string>('');
  private pageSubject = new BehaviorSubject<number>(1);
  private pageSizeSubject = new BehaviorSubject<number>(2);

  public data$ = this.dataSubject.asObservable();
  public sortColumn$ = this.sortColumnSubject.asObservable();
  public sortDirection$ = this.sortDirectionSubject.asObservable();
  public searchText$ = this.searchTextSubject.asObservable();
  public page$ = this.pageSubject.asObservable();
  public pageSize$ = this.pageSizeSubject.asObservable();

  private filteredDataSubject = new BehaviorSubject<any[]>([]);
  public filteredData$ = this.filteredDataSubject.asObservable();
  constructor() { }

  setData(data: any[]): void {
    this.dataSubject.next(data);
  }
  updateSort(column: string, direction: string): void {
    this.sortColumnSubject.next(column);
    this.sortDirectionSubject.next(direction);
  }

  setSearchText(searchText: string): void {
    this.searchTextSubject.next(searchText);
  }
  setPage(page: number): void {
    this.pageSubject.next(page);
  }

  setPageSize(pageSize: number): void {
    this.pageSizeSubject.next(pageSize);
  }

  downloadCSV(tableData?: any[]): void {
    let data: any[];
    if (tableData) {
      data = tableData;
    } else {
      data = this.dataSubject.getValue();      
    }
    const csvData = this.convertToCSV(data);
    const blob = new Blob([csvData], { type: 'text/csv' });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'table_data.csv';

    link.click();
  }

  private convertToCSV(data: any[]): string {
    const header = Object.keys(data[0]).join(',');
    const rows = data.map(item => Object.values(item).join(','));
    return `${header}\n${rows.join('\n')}`;
  }
  updateFilteredData(selectedPageSize: any, page: number, searchText: string, filterProperties: string[]): Observable<{ data$: Observable<any[]>, total: number }> {
    return new Observable<{ data$: Observable<any[]>, total: number }>(observer => {
      const data = this.dataSubject.getValue();
      const filteredData = data.filter((item) => {
        return (
          filterProperties.some(property => item[property].toLowerCase().includes(searchText.toLowerCase()))
        );
      });
      const total = filteredData.length; // Calculate total based on filteredData
      const startIndex = (page - 1) * selectedPageSize;
      const endIndex = startIndex + selectedPageSize;
      const paginatedData = filteredData.slice(startIndex, endIndex);

      const data$ = new Observable<any[]>(dataObserver => {
        dataObserver.next(paginatedData);
        dataObserver.complete();
      });

      observer.next({ data$: data$, total: total });
      observer.complete();
    });
  }


}
