import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { ApiService } from "src/app/shared/services/api.service";
import { UserService } from "src/app/shared/services/user.service";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller,
} from "swiper";
import { SwiperComponent } from "swiper/angular";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller,
]);

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
  isMobile = window.innerWidth <= 800;
  @ViewChild("swiper", { static: false }) swiper: any;
  @ViewChild("homeSwiper", { static: false }) homeSwiper: SwiperComponent;
  @ViewChild("newsModal", { static: true }) newsModal: any;

  slides = [
    { content: "Slide 1", customClass: "custom-slide-1" },
    { content: "Slide 2", customClass: "custom-slide-2" },
  ];
  bansCount: number;
  communitiesCount: number;
  streamers: { name: string; profileImage: string }[];
  newsList: any[] = [];
  selectedNews: any;
  private destroy = new Subject<void>();

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  ngOnInit(): void {
    const queryParams = this.route.snapshot.queryParams;
    if (queryParams["code"]) {
      this.apiService.login(queryParams["code"]).subscribe(async (res) => {
        await this.userService.getUserInfo();
        if (res) {
          this.router.navigate([], {
            queryParams: { code: null },
            queryParamsHandling: "merge",
          });
        }
      });
    }
    this.getMainPageData();
    this.getNewsData();
  }

  ngAfterViewInit(): void {}

  private getMainPageData() {
    this.apiService.getMainPageData().subscribe({
      next: (result) => {
        this.bansCount = result.bansCount;
        this.communitiesCount = result.communitiesCount;
        this.streamers = [...result.streamers, ...result.streamers];
      },
      error: () => {
        this.bansCount = 21342;
        this.communitiesCount = 579;
      },
    });
  }

  private getNewsData() {
    this.apiService.getNews().subscribe({
      next: (result: any) => {
        this.newsList = result;
      },
      error: (err) => {
        console.error("Failed to fetch news", err);
      },
    });
  }

  openModal(news: any) {
    this.selectedNews = news;
    this.modalService.open(this.newsModal, { centered: true });
  }

  slideChanged(event: any) {
    const slides = event[0].slides;
    const check = slides.length - event[0].realIndex;
    const indicesToScaleDown = this.getIndicesToScaleDown(check);
    this.applyTransitionAndTransform(slides, indicesToScaleDown, "scale(0.85)");
  }

  private applyTransitionAndTransform(
    slides: any,
    indices: number[],
    transformValue: string
  ) {
    slides.forEach((slide, i) => {
      slide.style.transition = "all .3s ease-in-out";
      slide.style.transform = indices.includes(i) ? transformValue : "scale(1)";
    });
  }

  private getIndicesToScaleDown(check: number): number[] {
    switch (check) {
      case 6:
        return [1, 5];
      case 7:
        return [0, 4];
      case 5:
        return [2, 6];
      default:
        return [];
    }
  }

  onSlideChange(event: any) {}

  ignoreWheelEvent = false;

  onWheel(event: WheelEvent) {
    event.preventDefault();
    if (this.ignoreWheelEvent) {
      return;
    }
    const delta = Math.sign(event.deltaY);
    if (delta > 0) {
      this.homeSwiper.swiperRef.slideNext(1000);
    } else if (delta < 0) {
      this.homeSwiper.swiperRef.slidePrev(1000);
    }
    this.ignoreWheelEvent = true;
    setTimeout(() => {
      this.ignoreWheelEvent = false;
    }, 1000);
  }
}
