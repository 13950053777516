import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { BehaviorSubject, Observable, firstValueFrom, map } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { User } from "../models/user-model";
import { PaymentMethod } from "../models/payment-models";
import { PaymentMethodType } from "../data/enums/payment";
import { UserLicense, UserLicenseResponse } from "../models/user-license-model";
import { loadTossPayments } from "@tosspayments/payment-sdk";
import BillingAuthRequest from "@tosspayments/payment__types/types/billing/BillingAuthRequest";

@Injectable({
  providedIn: "root",
})
export class UserService {
  public isLoggedIn: BehaviorSubject<boolean>;

  constructor(private http: HttpClient, private cookieService: CookieService) {
    this.isLoggedIn = new BehaviorSubject<boolean>(
      this.cookieService.check("access_token")
    );
  }

  public setLoginStatus(value: boolean): void {
    this.isLoggedIn.next(value);
  }

  public async getUserInfo(): Promise<User> {
    let headers = new HttpHeaders();
    const accessToken = this.cookieService.get("access_token");
    headers = headers.append("Authorization", `Basic ${accessToken}`);
    if (accessToken) {
      const userData = await firstValueFrom(
        this.http.get<{
          id: string;
          email: string;
          username: string;
          discord_id: string;
          permissions: number;
          email_verified: boolean;
          licensed: boolean;
          license_expires: string | null;
          subscription: {
            billing_status: number;
            card_number: string;
            expires: string;
            id: string;
            latest_order: {
              cancelled: boolean;
              cycle: number;
              id: string;
              ordered_at: string;
              plan: number;
              user_id: string;
            };
            next_billing_cycle: number;
            next_billing_plan: number;
            next_billing_type: number;
          } | null;
        }>(`${environment.infiEyeApi}/api/v1/users/@me`, { headers: headers })
      );
      if (userData) {
        this.cookieService.set("user_id", userData.id);
        this.cookieService.set("discord_id", userData.discord_id);
        this.cookieService.set("username", userData.username);
        this.cookieService.set(
          "user_permissions",
          userData.permissions.toString()
        );
      }
      const user: User = {
        discordId: userData.discord_id,
        email: userData.email,
        emailVerified: userData.email_verified,
        id: userData.id,
        licenseExpires: userData.licensed
          ? new Date(userData.license_expires)
          : null,
        licensed: false,
        permissions: userData.permissions,
        subscription: userData.subscription
          ? {
              cardNumber: userData.subscription.card_number,
              expires: new Date(userData.subscription.expires),
              latestOrder: {
                cancelled: userData.subscription.latest_order.cancelled,
                cycle: userData.subscription.latest_order.cycle,
                plan: userData.subscription.latest_order.plan,
                orderedAt: new Date(
                  userData.subscription.latest_order.ordered_at.replace(
                    " ",
                    "T"
                  )
                ),
              },
              nextBillingCycle: userData.subscription.next_billing_cycle,
              nextBillingPlan: userData.subscription.next_billing_plan,
              nextBillingType: userData.subscription.next_billing_type,
            }
          : null,
        username: userData.username,
      };
      return user;
    } else {
      throw new Error("Authorization required");
    }
  }

  public getPaymentMethods(): Observable<PaymentMethod[]> {
    let headers = new HttpHeaders();
    const cookies = document.cookie.split(";");
    const accessToken = cookies.find((cookie) =>
      cookie.includes("access_token")
    );
    if (accessToken) {
      headers = headers.append(
        "Authorization",
        `Basic ${accessToken.split("=")[1]}`
      );
      return this.http
        .get<
          {
            id: string;
            last4?: string;
            email?: string;
            type: string;
            invalid: boolean;
          }[]
        >(
          `${environment.infiEyeApi}/api/v1/users/@me/billing/payment-sources`,
          { headers: headers }
        )
        .pipe(
          map((response) =>
            response.map((item) => {
              const result: PaymentMethod = {
                id: item.id,
                invalid: item.invalid,
                type:
                  item.type === "TOSSPAYMENTS"
                    ? PaymentMethodType.tossPayment
                    : PaymentMethodType.paypal,
              };
              if (item.last4) result.cardInfo = item.last4;
              if (item.email) result.email = item.email;
              return result;
            })
          )
        );
    } else {
      throw new Error("Authorization required");
    }
  }

  public async addNewCard() {
    const customerKey = this.cookieService.get("user_id");
    const tossPayment = await loadTossPayments(
      environment.tossPaymentClientKey
    );
    const successUrl =
      "http://api.bory.io:3500/my-page/payment-method-management";
    const failUrl =
      "http://api.bory.io:3500/my-page/payment-method-management?tosspaymentRejected=true";
    const params: BillingAuthRequest = {
      customerKey: customerKey,
      successUrl: successUrl,
      failUrl: failUrl,
    };
    await tossPayment.requestBillingAuth("카드", params);
  }

  public addPaymentMethod(
    paymentType: PaymentMethodType,
    token: string
  ): Observable<PaymentMethod | null> {
    const body = {
      payment_type: paymentType,
      token: token,
    };
    return this.http
      .post<{
        id: string;
        last4: string;
        email?: string;
        type: string;
        invalid: false;
      }>(
        `${environment.infiEyeApi}/api//v1/users/@me/billing/payment-sources`,
        body,
        { observe: "response" }
      )
      .pipe(
        map((response) => {
          if (response.status === 200) {
            const result: PaymentMethod = {
              id: response.body.id,
              invalid: response.body.invalid,
              type:
                response.body.type === "TOSSPAYMENTS"
                  ? PaymentMethodType.tossPayment
                  : PaymentMethodType.paypal,
            };
            if (response.body.email) result.email = response.body.email;
            if (response.body.last4) result.cardInfo = response.body.last4;
            return result;
          } else {
            return null;
          }
        })
      );
  }

  public approvePaypalPayment(): Observable<string> {
    return this.http
      .post<{ url: string }>(
        `${environment.infiEyeApi}/api/v1/users/@me/billing/paypal/setup`,
        {}
      )
      .pipe(map((response) => response.url));
  }

  public deletePaymentMethod(methodId: string): Observable<boolean> {
    return this.http
      .delete(
        `${environment.infiEyeApi}/api/v1/users/@me/billing/payment-sources/${methodId}`,
        { observe: "response" }
      )
      .pipe(map((res) => res.status === 200));
  }

  getMyLicense(): Observable<UserLicense | null> {
    let headers = new HttpHeaders();
    const cookies = document.cookie.split(";");
    const accessToken = cookies.find((cookie) =>
      cookie.includes("access_token")
    );
    if (accessToken) {
      headers = headers.append(
        "Authorization",
        `Basic ${accessToken.split("=")[1]}`
      );
      return this.http
        .get<UserLicenseResponse>(
          `${environment.infiEyeApi}/api/v1/users/@me/license`,
          {
            headers: headers,
            observe: "response",
          }
        )
        .pipe(
          map((res) => {
            if (res.status === 200) {
              const result: UserLicense = {
                id: res.body.id,
                expires: new Date(res.body.expires),
                licensedAt: new Date(res.body.licensed_at),
                failed: res.body.failed,
              };
              return;
            } else {
              return null;
            }
          })
        );
    } else {
      throw new Error("Authorization required");
    }
  }

  getMyLicenseRecords(): Observable<UserLicense[]> {
    let headers = new HttpHeaders();
    const cookies = document.cookie.split(";");
    const accessToken = cookies.find((cookie) =>
      cookie.includes("access_token")
    );
    if (accessToken) {
      headers = headers.append(
        "Authorization",
        `Basic ${accessToken.split("=")[1]}`
      );
    }
    return this.http
      .get<UserLicenseResponse[]>(
        `${environment.infiEyeApi}/api/v1/users/@me/license/records`,
        {
          headers: headers,
        }
      )
      .pipe(
        map((res) => {
          return res.map((item) => {
            const result: UserLicense = {
              id: item.id,
              expires: new Date(item.expires),
              licensedAt: new Date(item.licensed_at),
              failed: item.failed,
            };
            return result;
          });
        })
      );
  }

  public get userId(): string {
    return this.cookieService.get("user_id");
  }

  public get discordId(): string {
    return this.cookieService.get("discord_id");
  }

  public get username(): string {
    return this.cookieService.get("username");
  }
}
