<swiper direction="vertical" #homeSwiper class="homeSlider" [mousewheel]="true" (wheel)="onWheel($event)"
  (slideChange)="onSlideChange($event)">
  <ng-template swiperSlide style="height: 100vh;" class="d-flex flex-column justify-content-center">
    <app-client-header></app-client-header>
    <div class="hero text-center">
      <div class="hero-content row">
        <div class="col-md-6">
          <h1 class="slogan">Protect Your Community from Threats</h1>
          <p class="sub-slogan">InFi-EYE는 관리를 위협하는 불법 활동 계정을 <br>
            차단하여 커뮤니티를 보호합니다
          </p>
          <a href="https://discord.com/api/oauth2/authorize?client_id=775723094257303552&permissions=8&scope=bot%20applications.commands"
            class="cta-button">
            <span class="cta-label">Invite InFi-EYE</span>
          </a>
        </div>
        <div class="col-md-6">
          <img src="../../../../assets/images/hero_image.png" width="400" alt="">
        </div>
      </div>
      <div class="row information" style="margin-top: 150px; margin-left: auto; margin-right: auto; padding-bottom: 0;">
        <div class="col-md-6">
          <div class="col-6" style="text-align: start;">
            <p>사용 스트리머 · 커뮤니티 : <span>{{ communitiesCount | number }}</span></p>
          </div>
          <div class="col-6" style="text-align: start;">
            <p>지금까지 차단된 계정 : <span>{{ bansCount | number }}</span></p>
          </div>
        </div>
        <div class="col-md-6 stats-column">
          <div class="swiper-container">
            <swiper #swiper [slidesPerView]="5" class="my-swiper"
              [navigation]="{ prevEl: '.swiper-button-prev', nextEl: '.swiper-button-next' }"
              (slideChange)="slideChanged($event)">
              <ng-template swiperSlide *ngFor="let streamer of streamers; let i = index">
                <div class="d-flex flex-column align-items-center pointer">
                  <img [src]="streamer.profileImage" [alt]="" class="image">
                  <span>{{ streamer.name }}</span>
                </div>
              </ng-template>
            </swiper>
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>


  <ng-template swiperSlide style="height: 100vh;" class="d-flex align-items-center justify-content-center">
    <div class="news-wrapper">
      <div class="news">
        <h1>News</h1>
        <div class="row">
          <div class="col-md-3" *ngFor="let news of newsList">
            <div class="news-header" [ngStyle]="{'background-image': 'url(' + news.image_url + ')'}">

            </div>
            <div class="news-body">
              <p style="word-break: keep-all">{{ news.title }}</p>
              <span style="word-break: keep-all">{{ news.description }}</span>
              <a class="cta-button" (click)="openModal(news)"> <span class="cta-label">Read More</span></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <!-- Modal Template -->
  <ng-template #newsModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title">{{ selectedNews?.title }}</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.close('Close click')"></button>
    </div>
    <div class="modal-body">
      <div [innerHTML]="selectedNews?.content"></div>
    </div>
  </ng-template>

  <ng-template swiperSlide style="height: 100vh;" class="d-flex flex-column justify-content-between">
    <div class="feature-wrapper">
      <div class="row features" style="margin-bottom: 12rem;">
        <h1>About</h1>
        <div class="col-lg-3 col-6 feature">
          <img src="../../../../assets/images/ic_00.png" width="50" alt="">
          <p class="feature-text">불법적 계정 차단</p>
        </div>
        <div class="vertical-divider"></div>
        <div class="col-lg-3 col-6 feature">
          <img src="../../../../assets/images/ic_01.png" width="50" alt="">
          <p class="feature-text">불법적 사용자와
            커뮤니티의 <br>
            지속적 식별, 차단</p>
        </div>
        <div class="vertical-divider"></div>
        <div class="col-lg-3 col-6 feature">
          <img src="../../../../assets/images/ic_02.png" width="50" alt="">
          <p class="feature-text">커뮤니티 관리 로그</p>
        </div>
        <div class="vertical-divider"></div>
        <div class="col-lg-3 col-6 feature">
          <img src="../../../../assets/images/ic_02.png" width="50" alt="">
          <p class="feature-text">InFi-EYE License
            클린유저 <br> 인증 시스템</p>
        </div>
      </div>
      <app-client-footer></app-client-footer>
    </div>
  </ng-template>
</swiper>