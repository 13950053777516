import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { License } from "../../shared/models/license-model";
import { DashboardData } from "../../shared/models/dashboard-data-model";
import { DashboardStatistics } from "../../shared/models/dashboard-statistics-model";
import { UserData } from "../../shared/models/user-data-model";
import { Community } from "../../shared/models/community-model";
import { DashboardStatisticsPeriod } from "../data/enums/dashboard-statistics-period";
import { ContentCategory } from "../data/enums/content-category";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Premium } from "src/app/shared/models/premium-model";
import { ReportData } from "../models/report-model";
import { UserBan } from "../models/user-ban-model";
import { CommunityBan } from "../models/community-ban-model";

@Injectable({
  providedIn: "root",
})
export class AdminService {
  constructor(private http: HttpClient) {}

  /**
   * @author rmchoi
   * @returns array of licenses
   */
  getLicensesList(
    page?: number,
    size?: number,
    searchKeyword?: string,
    searchFilter?: string
  ): Observable<{ data: License[]; totalItems: number }> {
    let params = new HttpParams();
    params = params.append("page", page);
    params = params.append("size", size);
    if (searchKeyword) params = params.append("word", searchKeyword);
    if (searchFilter) params = params.append("sf", searchFilter);
    return this.http
      .get(`${environment.api}/api/adm/manage/license`, {
        params: params,
        withCredentials: true,
      })
      .pipe(
        map((res) => {
          return {
            data: (res["content"] as any[]).map((value) => {
              const license: License = {
                id: value["lid"],
                userIp: value["userIp"],
                date: new Date(
                  (value["regDatetime"] as string).replace(" ", "T")
                ),
                discordId: value["discordId"],
              };
              return license;
            }),
            totalItems: res["totalElements"],
          };
        })
      );
  }

  /**
   * @author rmchoi
   * @returns data for admin dashboard page
   */
  getDashboardData(): Observable<DashboardData> {
    return this.http
      .get(`${environment.api}/api/adm/dashboard`, { withCredentials: true })
      .pipe(
        map((res) => {
          return res["data"] as DashboardData;
        })
      );
  }

  /**
   * @author rmchoi
   * @param period optinal DashboardStatisticsPeriod enum parameter
   * @param date target date
   * @returns data for dashboard statistics
   */
  getDashboardStatistics(
    period?: DashboardStatisticsPeriod,
    date?: Date
  ): Observable<DashboardStatistics> {
    let params = new HttpParams();
    if (period) {
      params = params.append("period", period);
    }
    if (date) {
      params = params.append(
        "baseDate",
        `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      );
    }
    return this.http
      .get(`${environment.api}/api/adm/dashboard/statistics`, {
        params: params,
      })
      .pipe(
        map((res) => {
          return res as DashboardStatistics;
        })
      );
  }

  getDashboardStatisticsRevenue(
    startDate: Date,
    endDate: Date
  ): Observable<{ count: number; revenue: number }> {
    let params = new HttpParams();
    params = params.append(
      "st_date",
      `${startDate.getFullYear()}-${
        startDate.getMonth() + 1
      }-${startDate.getDate()}`
    );
    params = params.append(
      "ed_date",
      `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`
    );
    return this.http
      .get<{
        revenue: { total_count: number; total_revenue: number };
      }>(`${environment.api}/api/adm/dashboard/statistics/revenue`, {
        params: params,
        withCredentials: true,
      })
      .pipe(
        map((value) => {
          return {
            count: value.revenue.total_count,
            revenue: value.revenue.total_revenue,
          };
        })
      );
  }

  /**
   * @author rmchoi
   * @returns Array of users
   */
  getUserList(
    page?: number,
    size?: number,
    searchKeyword?: string,
    searchFilter?: string
  ): Observable<{ data: UserData[]; totalItems: number }> {
    let params = new HttpParams();
    params = params.append("page", page);
    params = params.append("size", size);
    params = params.append("opt", "lookup");
    if (searchKeyword) params = params.append("word", searchKeyword);
    if (searchFilter) params = params.append("sf", searchFilter);
    return this.http
      .get(`${environment.api}/api/adm/manage/user`, {
        withCredentials: true,
        params: params,
      })
      .pipe(
        map((res) => {
          return {
            data: (res["content"] as any[]).map((value) => {
              const result: UserData = {
                email: value["email"] ?? "",
                username: value["username"],
                discordId: value["discordId"] ?? "",
                avatar: value["avatar"] ?? "",
                phone: value["phone"] ?? "",
                premium: value["premium"],
                discriminator: value["discriminator"] ?? "",
                cert: value["cert"],
                permission: value["permission"],
                regDatetime: new Date(
                  (value["regDatetime"] as string).replace(" ", "T")
                ),
                updDatetime: new Date(
                  (value["updDatetime"] as string).replace(" ", "T")
                ),
                certStDatetime: new Date(
                  (value["licenseStDatetime"] as string).replace(" ", "T")
                ),
                certEdDatetime: new Date(
                  (value["licenseEdDatetime"] as string).replace(" ", "T")
                ),
                accessToken: value["accessToken"] ?? "",
                uid: value["uid"],
              };
              return result;
            }),
            totalItems: res["totalElements"],
          };
        })
      );
  }

  /**
   * @author rmchoi
   * @param phone updated phone information
   * @param permission updated permission information
   * @param discordId updated discordId information
   * @returns true if operation was successful, else returns false
   */
  updateUser(permission: string, discordId: string): Observable<boolean> {
    let params = new HttpParams();
    params = params.append("opt", "modify");
    params = params.append("permission", permission);
    params = params.append("discordId", discordId);
    return this.http
      .get(`${environment.api}/api/adm/manage/user`, {
        params: params,
        withCredentials: true,
      })
      .pipe(
        map((res) => {
          return res["status"] === "success";
        })
      );
  }

  /**
   * @author rmchoi
   * @param discordId user's discord ID for deletion
   * @returns true if operation was successful, else returns false
   */
  deleteUser(discordId: string): Observable<boolean> {
    let params = new HttpParams();
    params = params.append("opt", "delete");
    params = params.append("discordId", discordId);
    return this.http
      .get(`${environment.api}/api/adm/manage/user`, {
        params: params,
        withCredentials: true,
      })
      .pipe(
        map((res) => {
          return res["status"] === "success";
        })
      );
  }

  /**
   * @author rmchoi
   * @description Save the information entered on the ToS text registration page or Privacy Policy text registration page to the DB.
   * @param title content title
   * @param content content description
   * @param category tardeg category (ToS/Privcay Policy)
   * @returns operation status and result message
   */
  updateContent(
    title: string,
    content: string,
    category: ContentCategory
  ): Observable<{ message: string; status: boolean }> {
    return this.http
      .post(`${environment.api}/adm/manage/contents`, {
        title: title,
        content: content,
        category: category,
      })
      .pipe(
        map((res) => {
          return {
            message: res["msg"],
            status: res["status"],
          };
        })
      );
  }

  /**
   * @author rmchoi
   * @param file target file to upload
   * @returns result message, result status flag, file url
   */
  updateContentFile(
    file: File
  ): Observable<{ message: string; fileUrl: string; status: boolean }> {
    const formData = new FormData();
    formData.append("file", file);
    return this.http
      .post(`${environment.api}/api/ckeditor/upload`, formData)
      .pipe(
        map((res) => {
          return {
            message: res["msg"],
            fileUrl: res["fileUrl"],
            status: res["status"] === "success" ? true : false,
          };
        })
      );
  }

  /**
   * @author rmchoi
   * @param searchKeyword search keyword
   * @param searchFilter filtering criteria
   * @param page page numer, default 1
   * @param size page size, default 10
   * @returns array of communinty entities and total number of entities
   */
  getCommunitiesAndStreamersList(
    searchKeyword?: string,
    searchFilter?: string,
    page?: number,
    size?: number
  ): Observable<{ data: Community[]; totalItems: number }> {
    let params = new HttpParams();
    if (searchKeyword) params = params.append("word", searchKeyword);
    if (searchFilter) params = params.append("sf", searchFilter);
    if (page) params = params.append("page", page);
    if (size) params = params.append("size", size);
    return this.http
      .get(`${environment.api}/api/adm/manage/contents/communityAndStreamer`, {
        params: params,
        withCredentials: true,
      })
      .pipe(
        map((res) => {
          return {
            data: (res["content"] as any[]).map((value) => {
              const result: Community = {
                name: value["name"],
                category: value["category"],
                manager: value["manager"],
                date: new Date(
                  (value["regDatetime"] as string).replace(" ", "T")
                ),
                image: value["profilePath"],
              };
              return result;
            }),
            totalItems: res["total"],
          };
        })
      );
  }

  /// get premium management list
  getPremiumList(
    page?: number,
    size?: number,
    searchKeyword?: string,
    searchFilter?: string
  ): Observable<{ data: Premium[]; totalItems: number }> {
    let params = new HttpParams();
    if (searchKeyword) params = params.append("word", searchKeyword);
    if (searchFilter) params = params.append("sf", searchFilter);
    if (page) params = params.append("page", page);
    if (size) params = params.append("size", size);
    return this.http
      .get(`${environment.api}/api/adm/manage/user/premium`, {
        params: params,
        withCredentials: true,
      })
      .pipe(
        map((res) => {
          return {
            data: (res["content"] as any[]).map((value) => {
              const result: Premium = {
                regDate: new Date(value["regDatetime"]),
                discordId: value["discordId"],
                totalPurchaseAmount: value["totalPurchaseAmount"],
                purchaseAmount: value["purchaseAmount"] ?? "",
              };
              return result;
            }),
            totalItems: res["totalElements"],
          };
        })
      );
  }

  getReportList(
    page?: number,
    size?: number,
    searchKeyword?: string,
    searchFilter?: string,
    reason?: string
  ): Observable<{ data: ReportData[]; totalItems: number }> {
    let params = new HttpParams();
    params = params.append("opt", "lookup");
    if (searchKeyword) params = params.append("word", searchKeyword);
    if (searchFilter) params = params.append("sf", searchFilter);
    if (page) params = params.append("page", page);
    if (size) params = params.append("size", size);
    if (reason) params = params.append("reason", reason);
    return this.http
      .get(`${environment.api}/api/adm/manage/user/report`, {
        params: params,
        withCredentials: true,
      })
      .pipe(
        map((res) => {
          return {
            data: (res["content"] as any[]).map((value) => {
              const result: ReportData = {
                reportId: value["reportId"],
                reportDate: new Date(
                  (value["regDatetime"] as string).replace(" ", "T")
                ),
                reporterUID: value["reporterId"],
                email: value["email"],
                status: value["process"],
                reviewer: value["manager"],
                reportDetails: value["content"],
              };
              return result;
            }),
            totalItems: res["totalElements"],
          };
        })
      );
  }

  acceptReport(reportId: string): Observable<boolean> {
    let params = new HttpParams();
    params = params.append("opt", "accept");
    params = params.append("reportId", reportId);
    return this.http
      .get(`${environment.api}/api/adm/manage/user/report`, {
        params: params,
        withCredentials: true,
      })
      .pipe(
        map((res) => {
          return res["status"] == "success";
        })
      );
  }

  rejectReport(reportId: string, reason: string): Observable<boolean> {
    let params = new HttpParams();
    params = params.append("opt", "reject");
    params = params.append("reportId", reportId);
    params = params.append("reason", reason);
    return this.http
      .get(`${environment.api}/api/adm/manage/user/report`, {
        params: params,
        withCredentials: true,
      })
      .pipe(
        map((res) => {
          return res["status"] == "success";
        })
      );
  }

  rejectAndEmailReport(reportId: string, reason?: string): Observable<boolean> {
    let params = new HttpParams();
    params = params.append("opt", "rejectMail");
    params = params.append("reportId", reportId);
    params = params.append("reason", reason);
    return this.http
      .get(`${environment.api}/api/adm/manage/user/report`, {
        params: params,
        withCredentials: true,
      })
      .pipe(
        map((res) => {
          return res["status"] == "success";
        })
      );
  }

  deleteReport(reportId: string): Observable<boolean> {
    let params = new HttpParams();
    params = params.append("opt", "delete");
    params = params.append("reportId", reportId);
    return this.http
      .get(`${environment.api}/api/adm/manage/user/report`, {
        params: params,
        withCredentials: true,
      })
      .pipe(
        map((res) => {
          console.log(res);
          return true;
        })
      );
  }

  getContent(category: ContentCategory) {
    return this.http
      .get<{
        cId: string;
        title: string;
        content: string;
        category: string;
        regDatetime: string;
        updDatetime: string;
        userIp: string;
      }>(`${environment.api}/api/contents/${category}`, {
        withCredentials: true,
      })
      .pipe(map((res) => (res ? res.content : "")));
  }

  modifyContent(category: ContentCategory, content: string) {
    const body = {
      title: "",
      content: content,
      category: category,
    };
    return this.http
      .post<{ msg: string; status: string }>(
        `${environment.api}/api/adm/manage/contents`,
        body,
        { withCredentials: true }
      )
      .pipe(map((res) => res.status));
  }

  getLicenseDetails(id: string): Observable<any> {
    let headers = new HttpHeaders();
    const cookies = document.cookie.split(";");
    const accessToken = cookies.find((cookie) =>
      cookie.includes("access_token")
    );
    if (accessToken) {
      headers = headers.append(
        "Authorization",
        `Basic ${accessToken.split("=")[1]}`
      );
      return this.http.get(
        `${environment.infiEyeApi}/api/v1/license/${id}/guilds`,
        { headers: headers }
      );
    } else {
      throw new Error("Authorization required");
    }
  }

  deleteLicenseDetails(id: string) {
    let headers = new HttpHeaders();
    const cookies = document.cookie.split(";");
    const accessToken = cookies.find((cookie) =>
      cookie.includes("access_token")
    );
    if (accessToken) {
      headers = headers.append(
        "Authorization",
        `Basic ${accessToken.split("=")[1]}`
      );
      return this.http.delete(
        `${environment.infiEyeApi}/api/v1/license/${id}`,
        {
          headers: headers,
        }
      );
    } else {
      throw new Error("Authorization required");
    }
  }

  deleteLicense(id: string): Observable<boolean> {
    let headers = new HttpHeaders();
    const cookies = document.cookie.split(";");
    const accessToken = cookies.find((cookie) =>
      cookie.includes("access_token")
    );
    if (accessToken) {
      headers = headers.append(
        "Authorization",
        `Basic ${accessToken.split("=")[1]}`
      );
      return this.http
        .post(`${environment.infiEyeApi}/api/v1/license/${id}`, {
          headers: headers,
        })
        .pipe(
          map((res) => {
            return true; // TODO: change after api is accessible
          })
        );
    } else {
      throw new Error("Authorization required");
    }
  }

  getHomePageLicenseInfo() {}

  modifyHomePageLicenseContent(content: string): Observable<boolean> {
    const body = {
      title: "",
      content: content,
      category: "license",
    };
    return this.http
      .post<{ msg: string; status: string }>(
        `${environment.api}/api/adm/manage/license`,
        body,
        { withCredentials: true }
      )
      .pipe(map((res) => res.status === "success"));
  }

  uploadTutorial(id: number, file: File): Observable<string> {
    const data = new FormData();
    data.append("file", file);
    data.append("tutorialId", `${id}`);
    return this.http
      .post<{ msg: string; fileUrl: string; status: string }>(
        `${environment.api}/api/adm/manage/tutorial`,
        data
      )
      .pipe(
        map((res) => {
          if (res.status === "success") {
            return res.fileUrl;
          } else {
            return "";
          }
        })
      );
  }

  getBannedUsersList(limit?: number): Observable<UserBan[]> {
    let headers = new HttpHeaders();
    const cookies = document.cookie.split(";");
    const accessToken = cookies.find((cookie) =>
      cookie.includes("access_token")
    );
    if (accessToken) {
      headers = headers.append(
        "Authorization",
        `Basic ${accessToken.split("=")[1]}`
      );
      if (limit) {
        headers = headers.append("limit", limit.toString());
      }
      return this.http
        .get<
          {
            code: string;
            banned_by: string;
            expires: string | null;
            guild_id: string;
            id: string;
            user_id: string;
          }[]
        >(`${environment.infiEyeApi}/api/v1/bans`, {
          headers: headers,
        })
        .pipe(
          map((res) => {
            return res.map((value) => {
              const data: UserBan = {
                id: value.id,
                userId: value.user_id,
                guildId: value.guild_id,
                banCode: Number.parseInt(value.code),
                bannedBy: value.banned_by,
                expires: value.expires,
              };
              return data;
            });
          })
        );
    } else {
      throw new Error("Authorization required");
    }
  }

  createUserBan(
    userId: string,
    guildId: string,
    banCode: number,
    expires?: string
  ): Observable<boolean> {
    let headers = new HttpHeaders();
    const cookies = document.cookie.split(";");
    const accessToken = cookies.find((cookie) =>
      cookie.includes("access_token")
    );
    if (accessToken) {
      const body: any = {
        user_id: userId,
        guild_id: guildId,
        ban_code: banCode,
      };
      if (expires) {
        body.expires = expires;
      }
      headers = headers.append(
        "Authorization",
        `Basic ${accessToken.split("=")[1]}`
      );
      return this.http
        .post(`${environment.infiEyeApi}/api/v1/bans`, body, {
          headers: headers,
          observe: "response",
        })
        .pipe(
          map((res) => {
            if (res.status === 201) {
              return true;
            } else {
              return false;
            }
          })
        );
    } else {
      throw new Error("Authorization required");
    }
  }

  globalBanwave() {
    throw new Error("Function is not implemented");
  }

  deleteUserBan(banId: string, reason: string): Observable<boolean> {
    let headers = new HttpHeaders();
    const cookies = document.cookie.split(";");
    const accessToken = cookies.find((cookie) =>
      cookie.includes("access_token")
    );
    if (accessToken) {
      const body = {
        reason: reason,
      };
      headers = headers.append(
        "Authorization",
        `Basic ${accessToken.split("=")[1]}`
      );
      return this.http
        .delete(`${environment.infiEyeApi}/api/v1/bans/${banId}`, {
          headers: headers,
          observe: "response",
          body: body,
        })
        .pipe(
          map((res) => {
            if (res.status === 200) {
              return true;
            } else {
              return false;
            }
          })
        );
    } else {
      throw new Error("Authorization required");
    }
  }

  getBannedCommunitiesList(limit?: number): Observable<CommunityBan[]> {
    let headers = new HttpHeaders();
    const cookies = document.cookie.split(";");
    const accessToken = cookies.find((cookie) =>
      cookie.includes("access_token")
    );
    if (accessToken) {
      headers = headers.append(
        "Authorization",
        `Basic ${accessToken.split("=")[1]}`
      );
      if (limit) headers = headers.append("limit", limit.toString());
      return this.http
        .get<{ id: string; description: string; ban_code: number }[]>(
          `${environment.infiEyeApi}/api/v1/ban-communities`,
          {
            headers: headers,
          }
        )
        .pipe(
          map((res) => {
            return res.map((value) => {
              const data: CommunityBan = {
                id: value.id,
                description: value.description,
                banCode: value.ban_code,
              };
              return data;
            });
          })
        );
    } else {
      throw new Error("Authorization required");
    }
  }

  createNewBanCommunity(
    gid: string,
    banCode: number,
    description?: string
  ): Observable<boolean> {
    let headers = new HttpHeaders();
    const cookies = document.cookie.split(";");
    const accessToken = cookies.find((cookie) =>
      cookie.includes("access_token")
    );
    if (accessToken) {
      const body: any = {
        id: gid,
        ban_code: banCode,
      };
      if (description) body.description = description;
      headers = headers.append(
        "Authorization",
        `Basic ${accessToken.split("=")[1]}`
      );
      return this.http
        .post(`${environment.infiEyeApi}/api/v1/ban-communities`, body, {
          headers: headers,
          observe: "response",
        })
        .pipe(
          map((res) => {
            if (res.status === 201) {
              return true;
            } else {
              return false;
            }
          })
        );
    } else {
      throw new Error("Authorization required");
    }
  }

  getCommunitiesList(): Observable<
    {
      date: Date | null;
      id: string;
      population: number;
      inviteLink: string;
      isActivated: boolean;
    }[]
  > {
    let headers = new HttpHeaders();
    const cookies = document.cookie.split(";");
    const accessToken = cookies.find((cookie) =>
      cookie.includes("access_token")
    );
    if (accessToken) {
      headers = headers.append(
        "Authorization",
        `Basic ${accessToken.split("=")[1]}`
      );
      return this.http
        .get<
          {
            activated: number;
            ban_settings: { [key: number]: string }[];
            id: string;
            locale: string;
          }[]
        >(`${environment.infiEyeApi}/api/v1/communities`, {
          headers: headers,
        })
        .pipe(
          map((res) => {
            return res.map((value) => {
              return {
                date: null,
                id: value.id,
                population: 0,
                inviteLink: "",
                isActivated: value.activated === 1,
              };
            });
          })
        );
    } else {
      throw new Error("Authorization required");
    }
  }
}
